import teamCanarySlice from "./teamCanarySlice";
import teamCanaryServices from "../../Services/teamCanaryServices";

export const AllTeamActions = teamCanarySlice.actions;

/**
 * ReduxAction - This function call services for phase1 api.
 *
 * @param {Object} cluster - The given cluster.
 * @param {string} API - This select only API canary true/false.
 * @param {number} GUI - This select only GUI canary true/false.
 */
const fetchAllTeamsPhase1 = (cluster, API, GUI) => {
  
  return async (dispatch) => {

    // It call the api services
    var result = await teamCanaryServices.getAllCanary(cluster);
    // console.log("Phase 1 ",result)
    const finalResult = {
      data: result.data,
      api: API,
      gui: GUI
    }
    if (result !== "") {
      dispatch(AllTeamActions.setAllTeamsPhase1(finalResult));
    }
    else {
      console.log("the internet is not avialable")
      // setting that network is broken as true
      dispatch(AllTeamActions.setNetworkIssue(true))
    }
  };
};

const fetchAllTeamsPhase2 = (cluster) => {
  return async (dispatch) => {
    var result = await teamCanaryServices.getAllCanary(cluster);
    // console.log("Phase 2 ",result)
    if (result !== "") {
      dispatch(AllTeamActions.setAllTeamsPhase2(result.data));
    }
    else {
      console.log("the internet is not avialable")
      // setting that network is broken as true
      dispatch(AllTeamActions.setNetworkIssue(true))
    }
  };
};


export { fetchAllTeamsPhase1, fetchAllTeamsPhase2 };
