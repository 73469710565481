import { createSlice } from "@reduxjs/toolkit";

const initialization = {
    teamsList: {},
    clusterHealth: "Healthy",
    loading:true,
    networkIssue:false
};

const teamCanarySlice = createSlice({
    name: "TeamCanary",
    initialState: initialization,
    reducers: {

        setAllTeamsPhase1(State, Action) {
            //Prepare the dictionary of all the team and cluster health
            let teamAllTeam = {}
            let clusterHealth = true
            let teamsLists = Action.payload.data;
            let gui_canary = Action.payload.gui;
            let api_canary = Action.payload.api;
            teamsLists.forEach((teamsList) => {

                // this help to select only phase1 data 
                if (!('phase2' in teamsList)) {

                    // this will select the gui_canary
                    if (teamsList.gui_canary && gui_canary === true) {
                        clusterHealth = clusterHealth & teamsList.status
                        if (teamsList.team_name in teamAllTeam) {
                            teamAllTeam[teamsList.team_name].push(teamsList)
                        } else {
                            teamAllTeam[teamsList.team_name] = [teamsList]
                        }
                    }

                    // this will select the api_canary
                    if (teamsList.gui_canary === false && api_canary === true) {
                        clusterHealth = clusterHealth & teamsList.status
                        if (teamsList.team_name in teamAllTeam) {
                            teamAllTeam[teamsList.team_name].push(teamsList)
                        } else {
                            teamAllTeam[teamsList.team_name] = [teamsList]
                        }
                    }
                }

            });
            State.networkIssue = false
            State.teamsList = teamAllTeam
            State.clusterHealth = clusterHealth ? "Healthy" : "Unhealthy"
        },


        setAllTeamsPhase2(State, Action) {
            //Prepare the dictionary of all the team and cluster health
            let teamAllTeam = {}
            let clusterHealth = true
            let teamsLists = Action.payload;
            teamsLists.forEach((teamsList) => {

                //we are only fetching teams of phase2
                if ('phase2' in teamsList && teamsList['phase2']) {
                    // console.log(teamsList)
                    clusterHealth = clusterHealth & teamsList.status

                    // if team repeated then we store all canary inside it
                    if (teamsList.team_name in teamAllTeam) {

                        let flag = true
                        for (let index in teamAllTeam[teamsList.team_name]) {
                            if (teamAllTeam[teamsList.team_name][index].canaryname === teamsList.canaryname) {
                                flag = false
                                teamAllTeam[teamsList.team_name][index].region += ',' + teamsList.region

                                teamAllTeam[teamsList.team_name][index]["regions"][teamsList.region] = {
                                    'status': teamsList.status
                                }
                                if(!teamsList.status){
                                     teamAllTeam[teamsList.team_name][index].status=teamsList.status  
                                     teamAllTeam[teamsList.team_name][index].status_num=teamsList.status_num         
                                }                 
                            }
                        }
                        if (flag) {
                            teamsList["regions"] = {}
                            teamsList["regions"][teamsList.region] = {
                                'status': teamsList.status
                            }
                            teamAllTeam[teamsList.team_name].push(teamsList)
                        }
                    } else {
                        teamsList["regions"] = {}
                        teamsList["regions"][teamsList.region] = {
                            'status': teamsList.status
                        }
                        teamAllTeam[teamsList.team_name] = [teamsList]
                    }
                }

            });
            // console.log(teamAllTeam, clusterHealth)
            State.networkIssue = false
            State.teamsList = teamAllTeam
            State.clusterHealth = clusterHealth ? "Healthy" : "Unhealthy"
        },

        setNetworkIssue(State,Action){
            let network = Action.payload
            if(network==true){
                State.loading = false
                State.teamsList = []
                State.networkIssue = true
            }
        }

    },
});

export default teamCanarySlice;
